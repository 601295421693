@import '/src/variables.sass'

html
  scroll-padding-top: 57px

body
  padding-top: 57px


#navbar
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.08))
  font-weight: 700

  @media (min-width: 768px)
    height: 57px

  a
    color: #212529
    font-family: 'Roboto', sans-serif
    font-style: normal

  a:hover
    color: #616161
