@import '/src/variables.sass'

#footer
  background-color: $color-dark-blue
  color: $color-cloud
  font-family: roboto, sans-serif
  font-weight: 500
  font-size: 20px

  p
    width: max-content
    margin: 0
    height: 87px
    display: flex
    align-items: center
    text-align: center

  img
    width: max-content
    margin-top: auto
    margin-bottom: auto
