#Home
  background-image: url('res/background.jpg')
  background-size: cover
  background-repeat: no-repeat
  background-position: center center
  height: 670px
  color: white

  .row
    height: 100%

  a
    width: 300px

  button
    width: 300px

  .home-logo
    max-width: 488px
    width: 100%
    max-height: 273px
    height: auto

  .description
    width: 600px

