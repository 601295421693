@import '/src/variables'
@import '/src/sharedStyles'

#publicationsTable
  border-color: $color-borders !important

  thead
    font-family: roboto, sans-serif
    font-size: 20px
    color: $color-dark-blue

    th
      font-weight: 700
