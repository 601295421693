@import '/src/variables'

#EditorPanel
  padding-bottom: 10px
  background-color: white
  border-width: 0 0 1px 0
  border-style: solid
  border-color: #7F8C8D
  border-radius: 5px 5px 0 0

  .container
    height: 100%

  button
    margin-top: 10px
    font-family: Roboto, sans-serif
    font-weight: 700
    font-size: 12pt

  @media screen and (max-width: 768px)
    .btn-group
      width: 100%
    .btn-toolbar
      display: block

  #spinner
    margin-top: 10px
    position: absolute
    right: 0
    @media screen and (max-width: 992px)
      left: 0

.queryButtonsFadeOut
  animation: 0.5s panelFadeOut forwards

.queryButtonsFadeIn
  animation: 0.5s panelFadeIn forwards

.spinnerAndQueryButtons
  position: relative

@keyframes panelFadeIn
  from
    opacity: 0
  to
    opacity: 1


@keyframes panelFadeOut
  from
    opacity: 1
  to
    opacity: 0
