@import '/src/variables.sass'

.planHelp
  position: absolute
  bottom: 0
  padding: 20px
  color: #9e9e9e
  font-family: 'Roboto', sans-serif
  font-size: 18px
  line-height: 18px
  user-select: none
  pointer-events: none

  .tipIcon
    position: relative
    bottom: 2px
    margin-right: 2px

  p:last-child
    margin-bottom: 0
