@import '/src/variables'

#Gallery
  margin-top: 70px
  margin-bottom: 24px
  padding-bottom: 20px
  background: rgb(17, 1, 186)
  background: linear-gradient(324deg, rgba(17, 1, 186, 1) 0%, rgba(70, 11, 143, 1) 55%, rgba(247, 78, 104, 1) 100%)
  padding-top: 60px
  color: white
  font-family: 'Roboto', sans-serif

  @media screen and (max-width: 1400px)
    .carousel-control-next, .carousel-control-prev
      width: 5%

  p
    font-size: 20px
    margin-bottom: 40px

    @media screen and (max-width: 1200px)
      margin-top: 50px

  .carousel
    min-height: 500px
    .carousel-item img
      max-height: 420px
      max-width: 100%
      border-radius: 10px


  @media screen and (min-width: 1200px)
    .slideImageCol
      max-width: 70%

