@import '/src/variables'

$color-table-borders-inner: $color-borders
$color-table-borders-outer: #7F8C8D

#resultTable
  table thead th
    font-weight: 700
    font-size: 20px !important

  table tbody th
    font-weight: 500

  table
    border-collapse: separate
    border-spacing: 0
    min-width: 350px

    tr th,
    tr td
      border-right: 1px solid $color-table-borders-inner
      border-bottom: 1px solid $color-table-borders-inner

    thead tr:first-child th
      border-top: 1px solid $color-table-borders-outer

    tr th:last-child
      border-right: 1px solid $color-table-borders-outer

    tr th:first-child
      border-left: 1px solid $color-table-borders-outer

    tbody tr:last-child th
      border-bottom: 1px solid $color-table-borders-outer

    tr th
      border-top: 1px solid $color-table-borders-inner

    /* top-left border-radius */


    thead tr:first-child th:first-child
      border-top-left-radius: 5px

    /* top-right border-radius */


    thead tr:first-child th:last-child
      border-top-right-radius: 5px

    /* bottom-left border-radius */


    tbody tr:last-child th:first-child
      border-bottom-left-radius: 5px

    /* bottom-right border-radius */


    tbody tr:last-child th:last-child
      border-bottom-right-radius: 5px



