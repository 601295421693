@import '/src/variables'

.float-end-fig
  margin-left: 20px
  float: right
  display: block
  img
    border: 1px solid $color-text-grey

.float-mid-fig
  width: 100%
  text-align: center
  img
    margin: auto
    border: 1px solid $color-text-grey

.float-beg-fig
  margin-left: 20px
  float: left
  display: block
  img
    border: 1px solid $color-text-grey
