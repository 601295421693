@import '/src/variables'

.publication
  td
    font-family: roboto, sans-serif
    font-size: 20px
    font-weight: 300

    a
      color: $color-light-blue

    .bibTex
      color: $color-light-blue
      text-decoration: underline
      cursor: pointer

body, .navbar
  padding-right: 0 !important

.modal-dialog, .form-control
  min-width: 300px
  max-width: fit-content
  block-size: fit-content

.bibtex-text
  background-color: #f5f5f5
