@import '/src/variables'

#plan
  border: 1px solid $color-text-grey
  border-radius: 5px
  background-color: #ffffff
  overflow: hidden

  .nav-tabs
    background-color: #fafafa
    border-radius: 5px

  .nav-item button
    border-radius: 0 !important

  .tab-content
    width: 100%
    height: fit-content

  .tab-pane
    position: relative
    height: 700px

#bottomPanel
  width: 100%
  border-top: 1px solid $color-text-grey
  border-radius: 0 0 5px 5px
  background-color: white
  padding: 5px

  p
    margin: 0

#cytoscapeNodes
  user-select: none
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  outline-style: none
  position: absolute
  z-index: 1

.cy
  position: absolute
  width: 100%
  height: 700px

.nodeInfo
  background-color: white
  color: black
  border: 1px solid $color-text-grey
  padding: 7px 10px 7px 10px
  width: fit-content
  line-height: 25px
  font-size: 16px
  box-shadow: #616161 3px 6px 10px

  b
    margin-right: 2px

.tippy-box[data-theme~='transparent']
  color: yellow
  background: none
