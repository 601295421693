@import '/src/variables.sass'

%card-font
  font-family: roboto, sans-serif
  font-weight: 300

.developerCard
  margin-bottom: 40px

.developerCard:last-of-type
  margin-bottom: 0

.developerName
  @extend %card-font
  margin: 0
  color: $color-dark-blue
  font-size: 32px
  font-weight: 300

.developerInfo
  @extend %card-font
  margin: 0
  color: $color-text-grey
  font-size: 20px
  line-height: 28px

.developerContacts
  @extend %card-font
  margin: 0
  color: $color-light-blue
  height: 44px
  font-size: 24px
  line-height: 28px
