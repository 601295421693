@import '/src/variables.sass'

#ExTeamMembers
  color: $color-text-grey
  margin-top: 60px
  margin-bottom: 30px
  font-family: 'Roboto', sans-serif
  font-weight: 400


#ExTeamMembers:after
  padding-top: 10px
  content: ""
  display: block
  border-bottom: 1px solid $color-borders
