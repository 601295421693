@import '/src/variables.sass'

.text
  text-align: justify
  font-size: 20px
  line-height: 1.6
  color: $color-text-grey
  font-family: 'Roboto', sans-serif

  p
    margin-bottom: 20px
